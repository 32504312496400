.about {
  margin: 20px 0;
  .container {
    .content-About {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70rem;
      .left {
        position: absolute;
        width: 45%;
        height: 50rem;
        bottom: 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        width: 55%;
        height: 31rem;
        position: absolute;
        background: rgb(243, 241, 241);
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        text-align: justify;
        padding: 60px;
        right: 3%;
        transform: translatex(-15%);
        transform: translateY(-10%);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.037);
        container-type: inline-size;
        height: max-content;
        overflow: hidden;

        h1 {
          font-size: 1.5rem;
          text-align: start;
          margin: 12px 0;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
            line-height: 1.2;
        }

        h2 {
          font-weight: 400;
          color: var(--SecondaryColor);
          font-size: 1.2rem;
          letter-spacing: 6px;
        }
        p {
          line-height: 23px;
          font-weight: 400;
          font-size: 0.8rem;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          color: rgba(0, 0, 0, 0.637);
          margin: 10px 0;
        }
        .images {
          max-width: 50%;
          height: 150px;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          object-fit: scale-down;

          img {
            width: 100%;
            height: 100%;
            padding-right: 30px;
          }
        }

        .btn {
          border: none;
          outline: none;
          background: var(--gradientColor);
          padding: 8px 25px;
          color: white;
          font-size: 0.8rem;
          font-weight: 600;
          margin-top: 10px;
          &:hover {
            background: var(--SecondaryColor);
            cursor: pointer;
          }
        }
      }

    }
    .content-quality {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.4rem;
      transform: translateY(-40%);
      margin-top: 20px;
      .left,
      .middle,
      .right {
        width: calc(40% - 30px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 1rem;
        }
        p {
          font-size: 0.7rem;
          color: rgba(0, 0, 0, 0.8);
          text-align:justify;
          word-spacing: 0.01rem;
        }
        .icon {
          background: var(--gradientColor);
          padding: 18px 30px;
          color: white;
          margin: 0 20px;
          border-radius: 9px;
        }
      }
    }
  }



  @media (max-width: 700px) {
    .container {
      margin: 0 0px;
      .content-About {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10rem;
        overflow: hidden;
        .left {

          position: relative;
          width: 100%;
          margin-bottom: 20px;
        }
        .right {
          position: relative;
          transform: translatex(0%);
          transform: translateY(0%);
          width: 100%;
          height: min-content;
          h1{
            font-size: calc(1.175rem + 1.5vw);
        }
     
        }
      }
    }
  }
  @media (max-width: 600px) {
    .container {
      .content-About {
        .right {
          container-type: inline-size;
      }
        .right >h1{
          font-size: 8cqi;
        }
        .right > h2{
          font-size: 5cqi;
        }
     
        
      }
    }
  }




  @media (max-width: 900px) {
    .container {
      .content-quality {
        margin: 0 30px;
        display: flex;
        flex-wrap: wrap;
        .left,
        .middle,
        .right {
          width: calc(55% - 40px);
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 799px) {
    .container {
      .content-quality {
        display: flex;
        flex-direction: column;
        transform: translateY(-36px);
        .left,
        .middle,
        .right {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
