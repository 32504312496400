@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   
}
:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgba(239, 239, 239, 0.886);
  --bodyColor: rgb(240, 240, 246);
  --cardBg: rgb(255, 225, 235);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--SecondaryColor);
  cursor: pointer;
}
.main {
  margin-top: 3rem;
  margin-bottom: 4rem;
  .container {
    .text {
      margin-bottom: 2rem;
    }
    .roww {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .cardDestination {
        width: calc(40% - 80px);
        background: rgb(236, 245, 247);
        height: max-content;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0 5px 10px rgb(156, 184, 189);
        padding-bottom: 20px;
        margin-bottom: 20px;

        .image {
          width: 100%;
          height: 200px;
          border-radius: 10px;
          border-top-left-radius: 10px;
          margin-bottom: 15px;
          overflow: hidden;
          

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: down;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            transition: transform 0.9s;
            &:hover {
              border: 4px solid white;
              transform: scale(1.3);
              cursor: pointer;
            }
          }
        }
        .title,
        .price,
        .divider,
        .details,
        .btn {
          padding-bottom: 10px;
          padding: 0 20px;
        }
        .title {
          h2 {
            font-size: 1rem;
            margin-bottom: 10px;
          }
          p {
            color: var(--greyText);
            .icon {
              font-size: 1rem;
              color: var(--textColor);
              margin-right: 3px;
              transform: translateY(10%);
            }
          }
        }
        .price {
          display: flex;
          justify-content: space-between;
          h3 {
            font-size: 0.8rem;
          }
          p {
            font-size: 0.8rem;
          }
        }
        .divider {
          width: 95%;
          height: 1px;
          background: #ccc;
          margin: 10px 5px;
        }
        .details {
          margin: 7px 0;
          p {
            font-size: 0.77rem;
            color: var(--textColor);
            text-align: justify;
            font-weight: 500;
          }
        }
        .btn {
          padding: 4px 30px;
          border: none;
          outline: none;
          border-radius: 30px;
          padding-top: 4px;
          background: var(--gradientColor);
          margin-top: 20px;
          cursor: pointer;
          a {
            display: inline-block;
            color: var(--whiteColor);
            transform: translateY(-10%);
            font-weight: 700;
            text-transform: capitalize;
            font-size: 0.7rem;
            .icon {
              margin: 1px 5px;
              font-size: 1.1rem;
              transform: translateY(23%);
            }
          }
          &:hover {
            background: var(--SecondaryColor);
          }
        }
        
      }
    }
  }
  @media (max-width: 1000px) {
    .container {
      margin: 0 50px;
      .roww {
         display: flex;
         flex-wrap: wrap;
        .cardDestination {
          width: calc(45% + 10px); 
        }
      }
       
      }
     
  }
  @media (max-width: 760px) {
    .container {
      .roww {
         display: flex;
         flex-direction: column;
         justify-items: center;
        .cardDestination {
          width: 76%;
          display: flex;
          flex-direction: column;
        }
      }
       
      }
     
  }
  @media (max-width: 590px) {
    .container {
      margin: 0;
      overflow: hidden;
      .roww {
         display: flex;
         flex-direction: column;
         justify-items: center;
         overflow: hidden;
        .cardDestination {
          width:calc(80% + 50px);
          min-width: 200px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
        }
      }
       
      }
     
  }
  
}
