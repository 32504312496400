$heroic: linear-gradient(to right, rgba(16, 181, 203, 0.3), rgba(0, 115, 168, 0.2));
.services {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url("asset/f1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 110px 0;

  .title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--SecondaryColor);
    container-type: inline-size;
  
    
    h2{
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 10px;
      margin-top: -10px;
    }
    h1{
      color: rgb(245, 240, 240);
      margin-top: 5px;
    }
  }
  .title > h2{
    font-size: 1.5cqi;
  }
  .title > h1{
    font-size: 3cqi;
  }
  .content-services {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .left,
    .middle,
    .right {
      width: calc(40% - 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: $heroic;
      backdrop-filter: blur(5px);
      padding: 50px 40px;
      margin: 0 20px;
      color: white;
      margin-top: 10px;

      h2{
        padding: 6px 0;
      }

      p {
        text-align: center;
        font-size: 0.7rem;
      }
      .icon {
        border: 2px solid white;
        color: white;
        padding: 15px 24px;
      }
      &:hover{
        background: var(--gradientColor);
        box-shadow: 0 5px 10px rgba(36, 58, 43, 0.779);
        cursor: pointer;
        transition: .5s;

      }
    }
  }

  @media (max-width: 1000px) {
   
    .container {
       margin: 0 100px;
      .content-services {
         display: flex;
         flex-wrap: wrap;

        .left,
        .middle,
        .right {
          width: calc(40% + 13px);
          display: flex;
          flex-direction: column;
          container-type: inline-size;
          h2{
            padding: 10px 0;
          }
        }
        .left > h2{
          font-size: 12cqi;
        }
        
        .right > h2 {
          font-size: 12cqi;
        }
        .middle > h2{
          font-size: 12cqi;
        }

      }
      .title{
         container-type: inline-size;
        h2{
          
          font-weight: 400;
          letter-spacing: 10px;
          margin-top: -10px;
        }
        h1{
          color: rgb(245, 240, 240);
          margin-top: 5px;
        }
      }
       
      }
      .title > h2{
        font-size: 2cqi;
      }
      .title > h1{
        font-size: 4cqi;
      }
     
  }
  @media (max-width: 770px) {
    .container { 
       .content-services {
         display: flex;
         flex-direction: column;
         justify-items: center;
         
        .left,
        .middle,
        .right {
          width: 80%;
          display: flex;
          flex-direction: column;
        }
      }
       
      }
     
  }
  @media (max-width: 507px) {
    .container { 
          margin: auto;
       .content-services {
         display: flex;
         flex-direction: column;
         justify-items: center;
         
        .left,
        .middle,
        .right {
          width: calc(80% + 100px);
          display: flex;
          flex-direction: column;
          transform: translateX(-60px);
        }
      }
       
      }
      .title{
        container-type: inline-size;
      }
      .title > h2{
        font-size: 6cqi;
      }
      .title > h1{
        font-size: 8cqi;
      }
     
  }
}
