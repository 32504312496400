@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgba(239, 239, 239, 0.886);
  --bodyColor: rgb(240, 240, 246);
  --cardBg: rgb(255, 225, 235);
}

.footer {
  width: 100%;
  position: relative;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url("f1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .flex {
    display: flex;
    align-items: center;
  }
  .grid {
    display: grid;
    align-items: center;
  }
  .imagediv {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      background: rgba(64, 69, 66, 0.307);
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      mix-blend-mode: multiply;
    }
  }
  .setContent {
    width: 100%;
    height: 100%;
    padding: 2rem initial;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    z-index: 10;
    .contactDiv {
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;
      .text {
        small {
          font-size: 13px;
          font-weight: 400;
          color: var(--whiteColor);
        }
        h2 {
          color: var(--whiteColor);
          font-size: 1.8rem;
        }
      }
      .inputDiv {
        width: 100%;
        gap: 1rem;
        flex-direction: column;
        input {
          width: 100%;
          padding: 0.5rem 0.6rem;
          border: 1px solid var(--whiteColor);
          outline: none;
          background: transparent;
          color: var(--whiteColor);
          border-radius: 3rem;
          &::placeholder {
            color: var(--whiteColor);
            opacity: 0.5;
          }
        }
        .btn {
          width: 100%;
          justify-content: center;
          color: var(--whiteColor);
          font-weight: 500;
          gap: 0.5rem;
          outline: none;
          border: none;
          background: var(--gradientColor);
          border-radius: 20px;
          padding: 8px 20px;
          color: white;
          cursor: pointer;
          &:hover {
            background: var(--SecondaryColor);
          }
        }
        .icon {
          font-size: 18px;
        }
      }
    }

    .footercard {
      position: relative;
      padding: 1rem 1rem 4rem;
      gap: 2rem;
      border-radius: 1rem;
      background: var(--bodyColor);
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      margin-top: 10px;
      .footerIntro {
        flex-basis: 50%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 0.5rem;
      }
      .logoDiv {
        .logo {
          color: var(--blackColor);
          font-weight: 600;
          .icon {
            font-size: 25px;
            color: var(--PrimaryColor);
            margin-right: 10px;
          }
        }
      }
      .footerParagraph {
        font-size: 12px;
        color: var(--textColor);
      }
      .footerSocials {
        column-gap: 0.5srem;
        .icon {
          color: var(--blackColor);
          font-size: 20px;
          &:hover {
            color: var(--PrimaryColor);
          }
        }
      }
      .footerLinks {
        flex-basis: 50%;
        width: 100%;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        .linkGroup {
          .title {
            display: block;
            font-weight: 600;
            color: var(--blackColor);
            margin-bottom: 0.5rem;
          }
          .footerList {
            font-size: 13px;
            color: var(--textColor);
            transition: 0.3s ease-in-out;
            cursor: pointer;
            .icon {
              font-size: 13px;
              color: var(--greyText);
            }
            &:hover {
              color: var(--PrimaryColor);
              transform: translateX(7px);

              .icon {
                color: var(--SecondaryColor);
              }
            }
          }
        }
      }
      .footerDiv {
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--SecondaryColor);
        padding: 0.5rem 2rem;
        small {
          font-size: 12px;
          color: var(--whiteColor);
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .footer {
    .setContent {
      .footercard {
        .footerLinks {
          grid-template-columns: repeat(3, 1fr);
          padding-bottom: 1rem;
        }
        .footerDiv {
          justify-content: space-between;
          flex-direction: row;
          small {
            font-size: 14px;
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 650px) {
  .footer {
    .setContent {
      .contactDiv {
        flex-direction: row;
        justify-content: space-between;
        .inputDiv {
          width: max-content;
          flex-direction: row;
          justify-content: flex-start;
          input {
            width: 65%;
          }
          .btn {
            width: max-content;
          }
        }
      }
    }
  }

}
@media screen and (min-width: 840px) {
  .footer {
    padding: 5rem 0;
    .setContent {
      .footercard {
        flex-direction: row;
        .footerLinks {
          grid-template-columns: repeat(3, 1fr);
          padding-bottom: 1rem;
        }
        .footerDiv{
          display: flex;
          justify-content: center;
          align-items: center;
    
        }
      }
    }
 
  }
}
@media screen and (min-width: 1024px) {
  .footer {
    .setContent {
      .footercard {
       padding: 3rem 1rem;
       .footerIntro{
        .footerParagraph{
          font-size: 15px;
          color: var(--textColor);
          text-align: justify;
        }
        .footerSocials{
          column-gap: .5rem;
          .icon{
            font-size: 25px;
            color: var(--textColor);
            &:hover{
              color: var(--PrimaryColor);
            }
          }
        }
       }
       .footerLinks{
        .linkGroup{
          .footerList{
            font-size: 13px;
            padding: 5px 0;
          }
        }
       }
       .footerDiv{
        display: flex;
        justify-content: center;
        align-items: center;

      }
      }
 
    }

  }
}
