.single {
  overflow: hidden;
}
.single .navBar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: var(--whiteColorDeam);
  z-index: 100;
  box-shadow: 0 3px 5px rgb(156, 184, 189);
  padding-top: 10px;
  margin-bottom: 40px;
}
.single .navBar .right-link ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.single .navBar .right-link ul li {
  padding: 3px 10px;
}
.single .navBar .right-link ul li a {
  color: var(--textColor);
  font-size: small;
  font-weight: 540;
}
.single .navBar .right-link ul li a:hover {
  color: var(--SecondaryColor);
}
.single .navBar .right-link ul .btn {
  padding: 3px 40px;
  border: none;
  outline: none;
  border-radius: 30px;
  background: var(--gradientColor);
  cursor: pointer;
}
.single .navBar .right-link ul .btn a {
  color: var(--whiteColor);
}
.single .navBar .right-link ul .btn:hover {
  background: var(--SecondaryColor);
}
.single .navBar .left-logo .logo {
  color: var(--blackColor);
  font-weight: bold;
  transform: translateY(7px);
}
.single .navBar .left-logo .icon {
  color: var(--PrimaryColor);
}
.single .content-single {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.single .content-single .left {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
}
.single .content-single .left .image {
  width: 110%;
  height: 70vh;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgb(169, 184, 186);
  overflow: hidden;
}
.single .content-single .left .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border: 4px solid white;
  border-radius: 20px;
  transition: transform 0.5s;
  cursor: pointer;
}
.single .content-single .left .image img:hover {
  border: 4px solid white;
  transform: scale(1.3);
}
.single .content-single .left h2 {
  color: var(--SecondaryColor);
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: 1rem;
}
.single .content-single .right {
  width: 80%;
  margin-top: 30px;
}
.single .content-single .right .cardd {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 80%;
  background: rgb(236, 245, 247);
  height: -moz-max-content;
  height: max-content;
  padding: 50px 20px;
  padding-left: 30px;
  padding-right: 90px;
  container-type: inline-size;
  margin-left: 80px;
  box-shadow: 0 5px 10px rgb(156, 184, 189);
  border-radius: 5px;
}
.single .content-single .right .cardd h2 {
  color: var(--SecondaryColor);
  letter-spacing: 5px;
}
.single .content-single .right .cardd .icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
.single .content-single .right .cardd .icons .icon {
  padding: 8px 0px 8px 10px;
  font-size: 1.8rem;
  color: var(--SecondaryColor);
  border: 1px solid var(--SecondaryColor);
  margin: 0 2px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
}
.single .content-single .right .cardd .icons .icon a {
  font-size: 0.8rem;
  transform: translateY(10px);
  color: var(--SecondaryColor);
}
.single .content-single .right .cardd .icons .icon:hover {
  background: var(--SecondaryColor);
  color: white;
}
.single .content-single .right .cardd .icons .icon:hover a {
  color: white;
}
.single .content-single .right .cardd .icons .call {
  display: flex;
  color: var(--SecondaryColor);
  font-size: 0.8rem;
}
.single .content-single .right .cardd .icons .call h1 {
  margin: 10px 0;
  letter-spacing: 5px;
  transform: translateY(-10px);
  margin-right: 20px;
}
.single .content-single .right .cardd .icons .call span {
  letter-spacing: 4px;
}
.single .content-single .right .cardd > h2 {
  font-size: 10cqi;
}
.single .bar {
  display: none;
}
.single .nav {
  position: fixed;
  right: 0;
  height: 100vh;
  width: 250px;
  z-index: 999;
  background: rgba(90, 108, 107, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  line-height: 3;
  transition: width 0.5s ease;
}
.single .nav .leftLogo {
  position: relative;
  background: linear-gradient(to right, rgba(16, 181, 203, 0.99), rgba(0, 115, 168, 0.99));
  height: -moz-max-content;
  height: max-content;
  padding: 9px 10px;
  display: flex;
  justify-content: Space-between;
}
.single .nav .leftLogo .logo {
  color: var(--blackColor);
  font-weight: bold;
}
.single .nav .leftLogo .icon {
  color: white;
}
.single .nav .leftLogo .close {
  color: white;
  cursor: pointer;
}
.single .nav .leftLogo .close .iconClose {
  font-size: 1.9rem;
  transform: translateY(30%);
}
.single .nav .rightLink {
  transform: translateY(0%);
}
.single .nav .rightLink ul {
  padding: 20px 20px;
}
.single .link {
  color: white;
}
.single .link:hover {
  color: var(--SecondaryColor);
}
.single .tagle-menu {
  display: none;
}
@media screen and (max-width: 770px) {
  .single .right-link {
    display: none;
  }
  .single .tagle-menu {
    display: flex;
    color: var(--PrimaryColor);
    cursor: pointer;
  }
}
@media screen and (max-width: 950px) {
  .single .content-single {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .single .content-single .right .cardd {
    width: 95%;
    height: -moz-max-content;
    height: max-content;
    margin-bottom: 10px;
    padding: 70px 50px;
    overflow: hidden;
    container-type: inline-size;
    overflow: hidden;
    margin-left: 0;
  }
  .single .content-single .right .call {
    position: relative;
    width: 100%;
    container-type: inline-size;
  }
  .single .content-single .right .call h1 {
    margin-right: auto;
  }
  .single .content-single .right .call p {
    transform: translateY(-5000px);
  }
  .single .content-single .right .call > span {
    font-size: 4cqi;
  }
  .single .content-single .right .call > h1 {
    font-size: 5.5cqi;
  }
  .single .content-single .left .image {
    width: 100%;
  }
}/*# sourceMappingURL=singlepage.css.map */