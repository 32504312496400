.description {
  .content-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 2px;
    padding: 50px 0;
    .left {
      flex-basis: 40%;
      .description {
        h2 {
          color: var(--SecondaryColor);
          margin-bottom: 0.4rem;
        }
        p {
          text-align: justify;
          font-size: 0.85rem;
        }
      }
      .location {
        h2 {
          color: var(--SecondaryColor);
        }
        .pro {
          p {
            font-weight: 600;
            color: grey;
            margin-right: 10px;
          }

          span {
            font-weight: 400;
            font-size: 0.78rem;
          }
        }
      }
      .dist {
        p {
          font-weight: 600;
          color: grey;
          margin-right: 10px;
        }

        span {
          font-weight: 400;
          font-size: 0.78rem;
        }
      }
    }
    .right {
      h2 {
        color: var(--SecondaryColor);
      }
      flex-basis: 50%;
      .map {
        width: 100%;
        height: 70vh;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .map {
      position: relative;
      height: 0;
      iframe {
        border: 0;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .content-description {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      .left,
      .right {
        flex-basis: 90%;
      }
      .left{
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }
}
