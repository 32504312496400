@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgba(239, 239, 239, 0.886);
  --bodyColor: rgb(240, 240, 246);
  --cardBg: rgb(255, 225, 235);
}

header {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url("asset/f1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
header .grid {
  display: grid;
  align-items: center;
}
header .navBar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: var(--whiteColorDeam);
  z-index: 100;
  box-shadow: 0 3px 5px rgba(56, 79, 69, 0.8);
  padding-top: 10px;
  color: rgba(90, 108, 107, 0.6);
}
header .navBar .right-link ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
header .navBar .right-link ul li {
  padding: 3px 10px;
}
header .navBar .right-link ul li a {
  color: var(--textColor);
  font-size: small;
  font-weight: 540;
}
header .navBar .right-link ul li a:hover {
  color: var(--SecondaryColor);
}
header .navBar .right-link ul .btn {
  padding: 3px 40px;
  border: none;
  outline: none;
  border-radius: 30px;
  background: var(--gradientColor);
  cursor: pointer;
}
header .navBar .right-link ul .btn a {
  color: var(--whiteColor);
}
header .navBar .right-link ul .btn:hover {
  background: var(--SecondaryColor);
}
header .navBar .left-logo {
  transform: translateY(-30%);
}
header .navBar .left-logo .logo {
  color: var(--blackColor);
  font-weight: bold;
  transform: translateY(7px);
}
header .navBar .left-logo .icon {
  color: var(--PrimaryColor);
}
header .tagle-menu {
  display: none;
}
@media screen and (max-width: 770px) {
  header .right-link {
    display: none;
  }
  header .tagle-menu {
    display: flex;
    color: var(--PrimaryColor);
    cursor: pointer;
  }
}
header .content {
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  top: 28%;
}
header .content .text {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--whiteColor);
}
header .content .text h1 {
  margin-top: 10px;
  font-size: 1.8rem;
  font-weight: 800;
}
header .content .text h2 {
  font-size: 1.1rem;
  font-weight: 600;
}
header .content .card {
  width: 100%;
  position: relative;
  background: var(--whiteColorDeam);
  height: -moz-max-content;
  height: max-content;
  padding: 36px 5px;
  border-radius: 7px;
  margin-top: 40px;
  box-shadow: 0 3px 5px rgb(21, 30, 21);
}
header .content .card form {
  display: flex;
  justify-content: space-around;
}
header .content .card form .textInput,
header .content .card form .textInput,
header .content .card form .price {
  display: flex;
  flex-direction: column;
}
header .content .card form .textInput label {
  font-size: 0.78rem;
  font-weight: 600;
  color: var(--textColor);
}
header .content .card form .dateInput label {
  font-size: 0.78rem;
  font-weight: 600;
  color: var(--textColor);
}
header .content .card form .dateInput .input {
  padding: 0 1rem;
}
header .content .card form .dateInput .input input {
  padding: 0 4rem;
}
header .content .card form .price span {
  font-size: 0.78rem;
  font-weight: 600;
  color: var(--textColor);
}
header .content .card form .price .input {
  padding: 0 1rem;
}
header .content .card form .price .input input {
  width: 100%;
  accent-color: var(--textColor);
  padding: 0 4rem;
}
header .content .card form .input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 3rem;
  background: rgba(224, 224, 224, 0.865);
  border-radius: 3rem;
  margin-top: 1rem;
}
header .content .card form .input input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: var(--greyText);
  font-weight: 400;
}
header .content .card form .input input::-moz-placeholder {
  font-size: small;
}
header .content .card form .input input::placeholder {
  font-size: small;
}
header .content .card form .input .icon {
  position: relative;
  font-size: 1rem;
  left: 20%;
}
header .content .card .filter {
  position: absolute;
  top: 92%;
  left: 39%;
}
header .content .card .filter .btn {
  padding: 7px 40px;
  border: none;
  outline: none;
  border-radius: 30px;
  background: var(--gradientColor);
  cursor: pointer;
}
header .content .card .filter .btn a {
  color: var(--whiteColor);
  font-size: 1rem;
  font-weight: 500;
}
header .content .card .filter .btn:hover {
  background: var(--SecondaryColor);
}
header .bar {
  display: none;
}
header .nav {
  position: fixed;
  right: 0;
  height: 100vh;
  width: 250px;
  z-index: 999;
  background: rgba(90, 108, 107, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  line-height: 3;
  transition: width 0.5s ease;
}
header .nav .leftLogo {
  position: relative;
  background: linear-gradient(to right, rgba(16, 181, 203, 0.99), rgba(0, 115, 168, 0.99));
  height: -moz-max-content;
  height: max-content;
  padding: 9px 10px;
  display: flex;
  justify-content: Space-between;
}
header .nav .leftLogo .logo {
  color: var(--blackColor);
  font-weight: bold;
}
header .nav .leftLogo .logo h2 {
  font-size: 0.3rem;
}
header .nav .leftLogo .icon {
  color: white;
}
header .nav .leftLogo .close {
  color: white;
  cursor: pointer;
}
header .nav .leftLogo .close .iconClose {
  font-size: 1.9rem;
  transform: translateY(30%);
}
header .nav .rightLink {
  transform: translateY(0%);
}
header .nav .rightLink ul {
  padding: 20px 20px;
}
header .link {
  color: white;
}
header .link:hover {
  color: var(--SecondaryColor);
}
header .cardDiv {
  position: relative;
  padding: 2rem 1rem;
  background: var(--whiteColor);
  gap: 1rem;
  border-radius: 10px;
}
header .cardDiv label {
  display: block;
  color: var(--textColor);
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
header .cardDiv .select {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
header .cardDiv .select select {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
header .cardDiv .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
header .cardDiv .input input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-size: 500;
}
header .cardDiv .input input::-moz-placeholder {
  font-size: 13px;
}
header .cardDiv .input input::placeholder {
  font-size: 13px;
}
header .cardDiv .icon {
  font-size: 18px;
  color: var(--PrimaryColor) !important;
  margin-left: 10px;
}
@media screen and (min-width: 600px) {
  header .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 750px) {
  header .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=navbar.css.map */