@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.blogs {
  .textt {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: var(--SecondaryColor);

    h1 {
      color: rgb(27, 27, 27);
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 1.7rem;
    }
  }
  .content-blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .blog {
      width: calc(35% - 30px);
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 80px;
      border-radius: 4px;
      box-shadow: 0 4px 5px rgba(155, 166, 166, 0.7);
      .image {
        position: relative;
        width: 100%;
        height: 30vh;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .blog-date {
          position: absolute;
          width: 60px;
          height: 45px;
          top: 30px;
          left: -15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: var(--gradientColor);

          h1 {
            font-size: 0.8rem;
          }
          small {
            font-size: 0.8rem;
            color: whitesmoke;
          }
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: -15px;
            left: 0;
            border: 8px solid;
            border-color: transparent var(--SecondaryColor)
              var(--SecondaryColor) transparent;
            z-index: -1;
          }
        }
      }
      .title {
        margin: 6px 0;
        h1 {
          color: var(--SecondaryColor);
          font-size: 1rem;
          margin: 4px 0px;
          padding: 2px 15px;
        }
        p {
          font-size: 0.75rem;
          text-align: justify;
          font-weight: 500;
          color: rgba(23, 23, 23, 0.888);
          padding: 10px 15px;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1000px) {
    .container {
      margin: 0 50px;
      .content-blog {
        display: flex;
        flex-wrap: wrap;
        .blog {
          width: calc(45% + 15px);
        }
      }
    }
  }
  @media (max-width: 720px) {
    .container {
      margin: auto;
      .content-blog {
        display: flex;
        flex-direction: column;
        justify-items: center;
        .blog {
          width: 80%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  @media (max-width: 460px) {
    .container {
      margin: auto;
      .content-blog {
        display: flex;
        flex-direction: column;
        justify-items: center;
        .blog {
          width: 90%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
